import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner,
} from 'reactstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { MultiValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import StoreState from '@store/StoreState';
import SelectedOptions from '@src/types/SelectedOptions';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { closeModal, openModal } from '@store/modal';
import FileType from '@src/components/file-uploader/types/FileType';
import AddPostValidationSchema from '../validation/AddPostValidationSchema';
import FileUploader from '../../../components/file-uploader/FileUploader';
import { addPost, getAllPosts } from '../store';
import '@styles/react/libs/react-select/_react-select.scss';
import Tags from '../enums/tags';
import useTags from '../hooks/useTags';
import TagsSelect from './TagsSelect';

interface AddPostProps {
  showModal: boolean,
  setShowModal: (_arg1: boolean) => void,
}

const AddPostModal = ({
  showModal, setShowModal,
}: AddPostProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: StoreState) => state.posts);

  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);

  const {
    tag,
    tagLabel,
    tagOptions,
    selectedValues,
    setSelectedValues,
    setNewTagAndTagOptions,
  } = useTags();

  const { t } = useTranslation();

  const defaultValues = {
    text: '',
    files: [],
    tagBy: null,
    tagIds: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues,
    resolver: yupResolver(AddPostValidationSchema(
      files,
      tag,
      selectedValues as MultiValue<SelectedOptions>,
    )),
  });

  const submitHandler: SubmitHandler<{ text: string }> = (values) => {
    setUploading(true);
    addPost({
      text: values.text,
      files: newFiles,
      tagBy: tag?.value as Tags,
      tagIds: selectedValues?.map((value) => value.value).join(',') || '',
    })
      .then(() => {
        dispatch(getAllPosts());
        setShowModal(false);
        reset(defaultValues);
        setFiles([]);
      }).then(() => setUploading(false)).catch(() => setUploading(false));
  };

  const confirmHandler = async () => {
    trigger().then((res) => {
      if (res && tag === null && selectedValues?.length === 0) {
        dispatch(openModal({
          Component: ConfirmationModalWrapper,
          componentProps: {
            handleConfirm: () => { handleSubmit(submitHandler)(); dispatch(closeModal()); },
            confirmButtonText: 'Add',
            closeButtonText: 'Cancel',
          },
          open: true,
          title: t('Warning'),
          subtitle: t('You have not selected any tags. Please note, this post will be visible to all parents. Are you sure you want to create this post?'),
          modalProps: {
            size: 'm',
          },
        }));
      } else {
        handleSubmit(submitHandler)();
      }
    });
  };

  useEffect(() => {
    if (showModal) {
      setFiles([]);
      setNewFiles([]);
      setNewTagAndTagOptions(null);
    }
  }, [showModal]);

  useEffect(() => {
    if (newFiles.length > 0) {
      trigger(['files']);
    }
  }, [newFiles]);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => { setShowModal(!showModal); }}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader className="bg-transparent" toggle={() => { setShowModal(!showModal); }} />
      { uploading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            backgroundColor: '#f9f9f9',
          }}
        >
          <Spinner size="lg" />
          &nbsp;
          {t('Uploading, please wait...')}
        </div>
      )}
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <h1 className="text-center mb-1">{t('Add New Post')}</h1>
        <p className="text-center">{t('Manage your posts list')}</p>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Label>{t('Description')}</Label>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <Input
                type="textarea"
                rows="4"
                id="description"
                invalid={errors.text !== undefined}
                {...field}
              />
            )}
          />
          {errors.text && <FormFeedback className="text-center">{errors.text.message}</FormFeedback>}
          <Row>
            <TagsSelect
              tag={tag}
              tagByOptions={store.selectedTags}
              onChangeTagByHandler={setNewTagAndTagOptions}
              tagLabel={tagLabel}
              selectedValues={selectedValues}
              tagOptions={tagOptions}
              setSelectedValues={setSelectedValues}
              errors={errors.tagIds}
            />
            <Col md={12} className="mt-1">
              <Label>{t('Media')}</Label>
              <Controller
                name="files"
                control={control}
                render={() => (
                  <FileUploader
                    files={files}
                    setFiles={setFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                  />
                )}
              />
              {errors.files
                && <p className="text-center text-danger" style={{ fontSize: '0.9rem' }}>{errors.files.message}</p>}
            </Col>
          </Row>
          <Row className="gy-1 gx-2 mt-75">
            <Col className="text-center mt-1" xs={12}>
              <Button
                type="button"
                className="me-1"
                color="primary"
                onClick={confirmHandler}
              >
                {t('Add')}
              </Button>
              <Button
                color="secondary"
                data-testid="closeAddModal"
                outline
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                {t('Close')}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddPostModal;
