import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import StoreState from '@store/StoreState';
import { MultiValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { PollsState } from '@src/views/polls/types';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import FileType from '@src/components/file-uploader/types/FileType';
import { editPostInfo, getAllPosts } from '../store';
import AddPostReq from '../types/AddPostReq';
import Post from '../types/Post';
import SelectedOptions from '../../../types/SelectedOptions';
import AddPostValidationSchema from '../validation/AddPostValidationSchema';
import FileUploader from '../../../components/file-uploader/FileUploader';
import Tags from '../enums/tags';
import useTags from '../hooks/useTags';
import TagSelect from './TagsSelect';

interface ViewDetailsProps {
  show: boolean,
  setShow: (_arg1: boolean) => void,
  currentPost: Post,
}

const ViewDetailsModal = ({ show, setShow, currentPost }: ViewDetailsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: StoreState) => state.posts);
  const pollStore = useSelector((state: PollsState) => state.polls);
  const [existingFiles, setExistingFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<number[]>([]);
  const { t } = useTranslation();
  const {
    tag,
    tagLabel,
    tagOptions,
    selectedValues,
    setSelectedValues,
    setTagOptionsByCurrentPost,
    setNewTagAndTagOptions,
  } = useTags();

  const defaultValues = {
    text: currentPost?.text,
    tags: '',
    files: [],
    tagBy: null,
    tagIds: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues,
    resolver: yupResolver(AddPostValidationSchema(
      existingFiles,
      tag,
      selectedValues as MultiValue<SelectedOptions>,
    )),
  });

  const handlePostFiles = async () => {
    if (currentPost.files) {
      setExistingFiles(currentPost.files.map((file) => ({ ...file, path: file.filepath })));
    }
  };

  useEffect(() => {
    if (show) {
      setTagOptionsByCurrentPost(currentPost);
      setDeletedFiles([]);
      setNewFiles([]);

      reset({
        text: currentPost.text,
      });
      handlePostFiles();
    }
  }, [
    currentPost,
    pollStore.selectedGroups,
    pollStore.selectedObjects,
    store.selectedChildren,
    show,
  ]);

  const submitHandler: SubmitHandler<AddPostReq> = (values) => {
    editPostInfo({
      text: values.text,
      files: newFiles,
      tagBy: tag?.value as Tags,
      tagIds: selectedValues?.map((value) => value.value).join(',') || '',
      deletedFiles: deletedFiles.join(',') || '',
    }, currentPost.id)
      .then(() => {
        dispatch(getAllPosts());
        setShow(false);
        setNewFiles([]);
      });
  };

  const confirmHandler = async () => {
    trigger().then((res) => {
      if (res && tag === null && selectedValues?.length === 0 && currentPost.tag !== null) {
        dispatch(openModal({
          Component: ConfirmationModalWrapper,
          componentProps: {
            handleConfirm: () => { handleSubmit(submitHandler)(); dispatch(closeModal()); },
            confirmButtonText: 'Save changes',
            closeButtonText: 'Cancel',
          },
          open: true,
          title: t('Warning'),
          subtitle: t('You have not selected any tags. Please note, this post will be visible to all parents. Are you sure you want to create this post?'),
          modalProps: {
            size: 'm',
          },
        }));
      } else {
        handleSubmit(submitHandler)();
      }
    });
  };

  useEffect(() => {
    if (newFiles.length > 0) {
      trigger(['files']);
    }
  }, [newFiles]);

  return (
    <Modal
      isOpen={show}
      toggle={() => { setShow(!show); }}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader className="bg-transparent" toggle={() => { setShow(!show); }} />
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <h1 className="text-center mb-1">{t('Edit post')}</h1>
        <p className="text-center">{t('Update your post here')}</p>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Label>{t('Description')}</Label>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <Input
                id="description"
                type="textarea"
                rows="4"
                invalid={errors.text !== undefined}
                {...field}
              />
            )}
          />
          {errors.text && <FormFeedback className="text-center">{errors.text.message}</FormFeedback>}
          <Row>
            <TagSelect
              tag={tag}
              tagByOptions={store.selectedTags}
              onChangeTagByHandler={setNewTagAndTagOptions}
              defaultValue={show
                ? store.selectedTags.find((f) => f.value === currentPost?.tag?.tag) : null}
              tagLabel={tagLabel}
              selectedValues={selectedValues}
              tagOptions={tagOptions}
              setSelectedValues={setSelectedValues}
              errors={errors.tagIds}
            />
            <Col md={12} className="mt-1">
              <Label>{t('Media')}</Label>
              <Controller
                name="files"
                control={control}
                render={() => (
                  <FileUploader
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                    files={existingFiles}
                    setFiles={setExistingFiles}
                    onDelete={(fileId) => setDeletedFiles((prevState) => [...prevState, fileId])}
                  />
                )}
              />
              {errors.files
                && <p className="text-center text-danger" style={{ fontSize: '0.9rem' }}>{errors.files.message}</p>}
            </Col>
          </Row>
          <Row className="gy-1 gx-2 mt-75">
            <Col className="text-center mt-1" xs={12}>
              <Button
                className="me-1"
                color="primary"
                type="button"
                onClick={confirmHandler}
              >
                {t('Save changes')}
              </Button>
              <Button
                outline
                color="secondary"
                onClick={() => {
                  setShow(!show);
                }}
              >
                {t('Close')}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ViewDetailsModal;
